import { H1 } from '@appliedsystems/applied-design-system'
import DOMPurify from 'dompurify'
import config from '../../../config/config'
import ContentImage from '../../Asi/ContentImage'

export default function LegacyPreview({ campaign }) {
    return (
        <>
            {campaign.content?.map(({ type, data, is_banner }, idx) => (
                <div
                    className={
                        'campaign-preview-body__item' + (is_banner ? ' campaign-preview-body__item--banner' : '')
                    }
                    key={idx}
                >
                    {type === 'library' && (
                        <div className="campaign-preview-body__library-item">
                            {data.medium_thumbnail_image && (
                                <div className="campaign-preview-body__library-item__thumbnail">
                                    <ContentImage
                                        src={data.medium_thumbnail_image}
                                        alt={data.thumbnail_alt}
                                        title={data.thumbnail_title}
                                    />
                                </div>
                            )}
                            <div className="campaign-preview-body__library-item__content">
                                <H1>{data.title}</H1>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(data.teaser_description_user),
                                    }}
                                />
                                <span className="preview-read-more">
                                    {data.language === config.general.LOCALE_FR_CA ? 'Lire plus' : 'Read More'}
                                </span>
                            </div>
                        </div>
                    )}
                    {type === 'manual' && (
                        <div
                            className="campaign-preview-body__manual-item"
                            dangerouslySetInnerHTML={{
                                // replace the read more hrefs with special value so they do nothing
                                // format is <a href="{{library_content_url|<external_id>|<language_id>}}">
                                __html: DOMPurify.sanitize(
                                    data.image_replaced_html_with_hashes.replace(
                                        /"\s*{{\s*library_content_url\|[^}]*}}\s*"/g,
                                        '"javascript: void(0);"',
                                    ),
                                ),
                            }}
                        />
                    )}
                </div>
            ))}
        </>
    )
}
